<template>
    <div class="home_container">
        <header class="my_header">
            <div class="header_container white_color">
                <div class="header_left">
                    <i class="header_l_svg" aria-hidden="true" @click="goBack"></i>
                </div>
                <div class="header_search">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <input type="text" placeholder="请输入商品搜索优惠券" class="white_color" @focus="goSearch">
                </div>
                <div class="header_right">
                    <router-link to="/version">
                        <i class="header_r_svg" aria-hidden="true"></i>
                    </router-link>
                </div>
            </div>
        </header>
        <footer class="my_footer">
            <div class="footer_container white_color">
                <div class="weui-tabbar">
                    <router-link to="home" class="weui-tabbar__item">
                        <div class="weui-tabbar__icon foot_menu_home">
                        </div>
                        <p class="weui-tabbar__label">首页</p>
                    </router-link>
                    <div class="weui-tabbar__item weui-bar__item--on">
                        <div class="weui-tabbar__icon foot_menu_list">
                        </div>
                        <p class="weui-tabbar__label">分类</p>
                    </div>
                    <router-link to="search" class="weui-tabbar__item">
                        <div class="weui-tabbar__icon foot_menu_search">
                        </div>
                        <p class="weui-tabbar__label">发现</p>
                    </router-link>
                    <router-link to="mine" class="weui-tabbar__item">
                        <div class="weui-tabbar__icon foot_menu_mine">
                        </div>
                        <p class="weui-tabbar__label">我的</p>
                    </router-link>
                </div>
            </div>
        </footer>
        <div class="main_container">
            <div class="left_container">
                <div class="list_item" @click="changeItem(index)" :key="item.id" v-for="(item,index) in listItems" :class="selectedItemId==index?'checked':''">{{ item.label }}</div>
            </div>
            <div class="right_container">
                <div class="item_info" :key="item.id" v-for="(item,index) in listItems" :class="selectedItemId==index?'blocked':'noblocked'">
                    <div class="info_title">{{ item.label }}</div>
                    <router-link :to="{ name: 'search', query: { title: i.subcname } }" class="info" :key="i.id" v-for="i in item.items">
                        <img :src="i.scpic" alt="">
                        <p class="gray">{{ i.subcname }}</p>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    created () {
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.islogin = true
            this.userInfo = userCookie
        }
        if (this.$route.params.items) {
            this.$route.params.items.forEach((item, index) => {
                if (index !== 0) {
                    this.listItems.push({
                        label: item.label,
                        items: item.labelItem
                    })
                }
            })
        } else {
            this.$nextTick(async function () {
                const { data: category } = await this.$http.get('goods/category')
                if (category.msg === '成功') {
                    category.data.forEach((item, index) => {
                        this.listItems.push({
                            label: item.cname,
                            items: item.subcategories
                        })
                    })
                }
            })
        }
    },
    data () {
        return {
            islogin: false,
            userInfo: {},
            // listItems
            listItems: [],
            selectedItemId: 0
        }
    },
    methods: {
        goBack () {
            this.$router.go(-1)
        },
        changeItem (index) {
            this.selectedItemId = index
        },
        goSearch () {
            this.$router.push('/search')
        }
    }
}
</script>
<style lang="less" scoped>
// header
.header_r_svg {
    background: url("../assets/home-message.svg");
}
.header_l_svg {
    margin-top: 13px;
    background: url("../assets/head-return.svg") no-repeat;
}
// main_container
.main_container {
    position: fixed;
    margin-top: 45px;
    width: 100%;
    overflow-x: hidden;
    display: flex;
    height: calc(100% - 93.8px);
    max-width: 640px;
}
// left_container
.left_container {
    width: 25%;
    height: 100%;
    overflow-y: scroll;
}
.list_item {
    width: 100%;
    background: #f6f6f6;
    color: #444;
    line-height: 50px;
    font-size: 14px;
    text-align: center;
    font-weight: 550;
}
.checked {
    background: #fff;
    color: #fd2b25;
}
// right_container
.right_container {
    width: 74.5%;
    height: 100%;
    overflow-y: scroll;
}
.item_info {
    background: #fff;
    clear: both;
}
.info_title {
    color: #262626;
    font-weight: 600;
    line-height: 50px;
    height: 50px;
    width: 50%;
    padding-left: 20px;
    font-size: 10px;
}
.info {
    width: 33.3333333%;
    text-align: center;
    margin-bottom: 12px;
    float: left;
    font-family: Arial, Helvetica, sans-serif;
}
.info img {
    width: 80%;
}
.gray {
    color: #444;
    font-size: 13px;
}
</style>
