<template>
    <div class="home_container">
        <header class="my_header">
            <div class="header_container white_color">
                <div class="header_left">
                    <router-link :to="{ name: 'classify', params: { items: this.items } }">
                        <i class="header_l_svg" aria-hidden="true"></i>
                    </router-link>
                </div>
                <div class="header_search home_header_search">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <input type="text" placeholder="请输入商品搜索优惠券" class="white_color" @focus="goSearch">
                </div>
                <div class="header_right home_header_right">
                    <!-- <span class="btndh" v-if="!this.islogin" @click="pushLogin">登录</span> -->
                    <!-- <span class="btndh" v-if="this.islogin && this.userInfo" @click="openKf">客服</span> -->
                    <span class="btndh" @click="openKf">客服</span>
                </div>
            </div>
        </header>
        <footer class="my_footer">
            <div class="footer_container white_color">
                <div class="weui-tabbar">
                    <div class="weui-tabbar__item weui-bar__item--on">
                        <div class="weui-tabbar__icon foot_menu_home">
                        </div>
                        <p class="weui-tabbar__label">首页</p>
                    </div>
                    <router-link :to="{ name: 'classify', params: { items: this.items } }" class="weui-tabbar__item">
                        <div class="weui-tabbar__icon foot_menu_list">
                        </div>
                        <p class="weui-tabbar__label">分类</p>
                    </router-link>
                    <router-link to="search" class="weui-tabbar__item">
                        <div class="weui-tabbar__icon foot_menu_search">
                        </div>
                        <p class="weui-tabbar__label">发现</p>
                    </router-link>
                    <router-link to="mine" class="weui-tabbar__item">
                        <div class="weui-tabbar__icon foot_menu_mine">
                        </div>
                        <p class="weui-tabbar__label">我的</p>
                    </router-link>
                </div>
            </div>
        </footer>
        <div class="main_container">
            <ly-tab v-model="selectedId" :items="items" :options="options" :change="selectChange()">
            </ly-tab>
            <swiper class="swiper1" v-if="iskeep && swiperItem.length>0" :options="swiperOption" :class="selectedId==0?'blocked':'noblocked'">
                <swiper-slide :key="item.id" v-for="(item,index) in swiperItem">
                    <div>
                        <img :src="item.materiallink" alt="" @click="select_activity(index)">
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
            <swiper class="swiper2" v-if="iskeep" :options="swiperOption2" :class="selectedId==0?'noblocked':'blocked'">
                <swiper-slide :key="item.id" v-for="(item,index) in labelItem">
                    <div @click="selectLabel(index, item.subcname)">
                        <img :src="item.scpic" alt="">
                        <p :class="selectLabelId==index?'high_light':''">{{ item.subcname }}</p>
                    </div>
                </swiper-slide>
                <div class="swiper-scrollbar" slot="scrollbar"></div>
            </swiper>
            <div class="products_container" :class="isfixed==true?'fixed':''">
                <ul class="discounts_items">
                    <li @click="changeItem(index)" :class="discountsIndex==index?'active':''" :key="item.id" v-for="(item, index) in discountsItem">{{ item.label }}</li>
                </ul>
            </div>
            <div class="tips_container">
                <span class="tip_left">全网精选优惠券</span>
                <i class="tip_right">百万商品实时更新</i>
            </div>
            <div class="coupons_container" :class="isinitloading?'noblocked':'blocked'">
                <div class="coupon_container animated fadeInUp" :key="item.id" v-for="item in couponList">
                    <div class="coupon">
                        <router-link :to="{ name: 'detail', query: { goodsId: item.goodsId, id: item.iid } }" class="coupon_position">
                            <div class="coupon_img">
                                <img :src="item.src" alt="">
                            </div>
                            <div class="coupon_info">
                                <div class="coupon_title">{{ item.title }}</div>
                                <div class="coupon_sailinfo">
                                    <span class="float_left">原价&nbsp;￥&nbsp;{{ item.old_price }}</span>
                                    <span class="float_right">已售&nbsp;{{ item.sell_num }}&nbsp;件</span>
                                </div>
                            </div>
                            <div class="coupon_money">
                                <p class="coupon_price">券后&nbsp;￥&nbsp;<em class="sell_price">{{ item.new_price }}</em></p>
                                <p class="coupon_discount quan_bg"><span class="num_discount">{{ item.quan_num }}元券</span></p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="loading" :class="isblank?'noblocked':'inline'">载入中，请稍候
                <span class="weui-primary-loading"></span>
            </div>
            <div class="loading" :class="isblank?'inline':'noblocked'">加载完成，无更多数据
            </div>
        </div>
        <div class="back_top" :class="isblock==true?'blocked':'noblocked'" @click="backTop"></div>
        <div class="activity_container" v-if="isactivity">
            <div class="activity_box">
                <div class="pic_close">
                    <div class="close_btn close_icon" @click="closeActivity"></div>
                </div>
                <div class="activity_title">{{ this.activity.name }}</div>
                <div class="activity_img">
                    <img :src="this.activity.materiallink" alt="">
                </div>
                <div class="activity_time">
                    <span class="time_left">活动开始时间：</span>
                    <span class="time_right">{{ this.activity.starttime }}</span>
                </div>
                <div class="activity_time clearfix">
                    <span class="time_left">活动结束时间：</span>
                    <span class="time_right">{{ this.activity.endtime }}</span>
                </div>
                <div class="activity_tpwd clearfix">
                    <span>活动口令</span>
                    <div class="activity_input">
                        <el-input v-model="activity.tpwd" type="text" :disabled="true" v-clipboard:copy="activity.tpwd" v-clipboard:success="tpwdCopy"></el-input>
                    </div>
                </div>
                <div class="activity_btn">
                    <el-button type="primary" v-clipboard:copy="activity.tpwd" v-clipboard:success="tpwdCopy">复制口令</el-button>
                </div>
            </div>
        </div>
        <div class="activity_container" v-if="iskf">
            <div class="kf_box">
                <div class="kf_title">长按保存，微信识别</div>
                <div class="kf_content clearfix">
                    <div class="kf">
                        <div class="kf_img">
                            <img src="../assets/kf.jpg" alt="">
                        </div>
                        <span>加入客服群，免费领取多种礼品</span>
                    </div>
                </div>
                <div class="kf_button">
                    <el-button type="primary" @click="closeKf" size="mini">关闭窗口</el-button>
                </div>
            </div>
        </div>
        <div :class="istoast?'blocked':'noblocked'">
            <div class="weui-mask_transparent"></div>
            <div class="weui-toast weui-toast_text-more">
                <i class="weui-icon-success-no-circle weui-icon_toast"></i>
                <p class="weui-toast__content">{{ toastContent1 }}</p>
                <p class="weui-toast__content">{{ toastContent2 }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted () {
        window.addEventListener('scroll', this.watchScroll)
    },
    destroyed () {
        window.removeEventListener('scroll', this.watchScroll)
    },
    created () {
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.islogin = true
            this.userInfo = userCookie
            this.iskf = false
        }
        this.$nextTick(async function () {
            const { data: swiperItem } = await this.$http.get('getswiper')
            this.swiperItem = swiperItem.data
            const { data: category } = await this.$http.get('goods/category')
            if (category.msg === '成功') {
                category.data.forEach((item, index) => {
                    this.items[item.cid].labelItem = item.subcategories
                    this.items[item.cid].label = item.cname
                    this.items[item.cid].parameter.cid = item.cid
                })
            }
            this.parameter = {
                pageid: 1,
                pagesize: 20,
                cid: '',
                sort: ''
            }
            this.src = 'goods/height'
            const { data: res } = await this.$http.post(this.src, this.parameter)
            if (res.msg === '成功') {
                if (res.data.length < this.parameter.pagesize) {
                    this.isblank = true
                }
                res.data.list.forEach((i, index) => {
                    if (!i.goodsId | i.goodsId === -1) {
                        i.goodsId = ''
                    }
                    if (!i.id | i.id === -1) {
                        i.id = ''
                    }
                    this.couponList.push({
                        src: i.mainPic,
                        title: i.title,
                        old_price: i.originalPrice,
                        sell_num: i.monthSales,
                        new_price: i.actualPrice,
                        quan_num: i.couponPrice,
                        iid: i.id,
                        goodsId: i.goodsId
                    })
                })
            } else {
                this.isblank = true
            }
            this.isloading = false
            this.isinitloading = false
        })
    },
    activated () {
        this.iskeep = true
        window.scrollTo(0, this.initscroll)
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.islogin = true
            this.userInfo = userCookie
        } else {
            this.islogin = false
            this.userInfo = {}
        }
    },
    deactivated () {
        this.iskeep = false
        this.initscroll = this.scrollTop
    },
    data () {
        return {
            initscroll: 0,
            iskf: true,
            istoast: false,
            toastContent1: '',
            toastContent2: '',
            isactivity: false,
            activity: {},
            islogin: false,
            userInfo: {},
            swiperItem: [],
            src: null,
            parameter: null,
            iskeep: true,
            isinitloading: true,
            isloading: true,
            isblank: false,
            // discounts_items
            discountsItem: [{
                label: '精选',
                src: 'goods/height',
                parameter: {
                    pageid: 1,
                    pagesize: 20,
                    cid: '',
                    sort: ''
                }
            }, {
                label: '实时榜',
                src: 'goods/ranking',
                parameter: {
                    ranktype: 1,
                    pageid: 1,
                    pagesize: 20,
                    cid: ''
                }
            }, {
                label: '日榜',
                src: 'goods/ranking',
                parameter: {
                    ranktype: 2,
                    pageid: 1,
                    pagesize: 20,
                    cid: ''
                }
            }, {
                label: '折上折',
                src: 'goods/discount',
                parameter: {
                    pageid: 1,
                    pagesize: 20,
                    sort: 0
                }
            }, {
                label: '9.9包邮',
                src: 'goods/nine',
                parameter: {
                    pageid: 1,
                    pagesize: 20,
                    ninecid: 1
                }
            }],
            discountsIndex: 0,
            // isfixed
            isfixed: false,
            // isblock
            isblock: false,
            // scrollTop
            scrollTop: 0,
            // labelItems
            labelItem: null,
            selectLabelId: null,
            // couponList
            couponList: [],
            // swiper
            swiperOption: {
                pagination: '.swiper-pagination',
                autoplay: 2000,
                observer: true,
                observeParents: true,
                autoplayDisableOnInteraction: false,
                paginationType: 'bullets'
            },
            swiperOption2: {
                scrollbar: '.swiper-scrollbar',
                slidesPerColumnFill: 'row',
                slidesPerView: 5,
                spaceBetween: 10,
                slidesOffsetBefore: 10,
                slidesOffsetAfter: 10
            },
            // ly-tab
            selectedId: 0,
            lastSelectId: 0,
            items: [
                {
                    label: '每日推荐',
                    src: 'goods/height',
                    parameter: {
                        pageid: 1,
                        pagesize: 20,
                        cid: '',
                        sort: ''
                    }
                }, {
                    label: '女装',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 1
                    }
                }, {
                    label: '母婴',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 2
                    }
                }, {
                    label: '美妆',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 3
                    }
                }, {
                    label: '居家日用',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 4
                    }
                }, {
                    label: '鞋品',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 5
                    }
                }, {
                    label: '美食',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 6
                    }
                }, {
                    label: '文娱车品',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 7
                    }
                }, {
                    label: '数码家电',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 8
                    }
                }, {
                    label: '男装',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 9
                    }
                }, {
                    label: '内衣',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 10
                    }
                }, {
                    label: '箱包',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 11
                    }
                }, {
                    label: '配饰',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 12
                    }
                }, {
                    label: '户外运动',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 13
                    }
                }, {
                    label: '家装家纺',
                    src: 'goods/ranking',
                    parameter: {
                        ranktype: 1,
                        pageid: 1,
                        pagesize: 20,
                        cid: 14
                    }
                }
            ],
            options: {
                activeColor: '#1d98bd'
            },
            // platformItems
            platformItems: [{
                label: '淘宝',
                src: require('../assets/logo.png')
            }, {
                label: '京东',
                src: require('../assets/refresh.png')
            }],
            selectedPlatformId: 0
        }
    },
    methods: {
        logout () {
            window.sessionStorage.clear()
            this.$router.push('/login')
        },
        // ly-tab
        selectChange () {
            if (this.lastSelectId !== this.selectedId) {
                if (this.selectedId === 0) {
                    this.discountsItem = [{
                        label: '精选',
                        src: 'goods/height',
                        parameter: {
                            pageid: 1,
                            pagesize: 20,
                            cid: '',
                            sort: ''
                        }
                    }, {
                        label: '实时榜',
                        src: 'goods/ranking',
                        parameter: {
                            ranktype: 1,
                            pageid: 1,
                            pagesize: 20,
                            cid: ''
                        }
                    }, {
                        label: '日榜',
                        src: 'goods/ranking',
                        parameter: {
                            ranktype: 2,
                            pageid: 1,
                            pagesize: 20,
                            cid: ''
                        }
                    }, {
                        label: '折上折',
                        src: 'goods/discount',
                        parameter: {
                            pageid: 1,
                            pagesize: 20,
                            sort: 0
                        }
                    }, {
                        label: '9.9包邮',
                        src: 'goods/nine',
                        parameter: {
                            pageid: 1,
                            pagesize: 20,
                            ninecid: 1
                        }
                    }]
                } else {
                    this.discountsItem = [{
                        label: '精选',
                        src: 'goods/height',
                        parameter: {
                            pageid: 1,
                            pagesize: 20,
                            cid: '',
                            sort: ''
                        }
                    }, {
                        label: '实时榜',
                        src: 'goods/ranking',
                        parameter: {
                            ranktype: 1,
                            pageid: 1,
                            pagesize: 20,
                            cid: ''
                        }
                    }, {
                        label: '日榜',
                        src: 'goods/ranking',
                        parameter: {
                            ranktype: 2,
                            pageid: 1,
                            pagesize: 20,
                            cid: ''
                        }
                    }, {
                        label: '低价优惠',
                        src: 'goods/explosive',
                        parameter: {
                            pageid: 1,
                            pagesize: 20,
                            pricecid: 1,
                            cids: ''
                        }
                    }, {
                        label: '实惠优品',
                        src: 'goods/explosive',
                        parameter: {
                            pageid: 1,
                            pagesize: 20,
                            pricecid: 3,
                            cids: ''
                        }
                    }]
                }
                this.selectLabelId = null
                this.isloading = true
                this.isinitloading = true
                this.couponList = []
                this.discountsIndex = 0
                this.isblank = false
                this.src = this.items[this.selectedId].src
                this.parameter = this.items[this.selectedId].parameter
                this.doSearch()
            }
            this.lastSelectId = this.selectedId
            this.labelItem = this.items[this.selectedId].labelItem
        },
        // choose_items
        changeItem (index) {
            this.discountsIndex = index
            document.documentElement.scrollTop = document.body.scrollTop = 0
            this.isinitloading = true
            this.isloading = true
            this.couponList = []
            this.isblank = false
            this.src = this.discountsItem[index].src
            this.parameter = this.discountsItem[index].parameter
            if (this.selectedId !== 0) {
                if (index < 2) {
                    this.parameter.cid = this.selectedId
                } else {
                    this.parameter.cids = this.selectedId
                }
            } else {
                this.parameter.cid = ''
                this.parameter.cids = ''
            }
            this.doSearch()
        },
        // watchScroll
        watchScroll () {
            const that = this
            const distance = null
            that.distance = distance
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            const scrollHeight = document.body.scrollHeight
            const clientHeight = document.body.clientHeight
            const scrollBottom = scrollHeight - clientHeight - scrollTop
            if (scrollBottom < 100 && !this.isloading && !this.isblank && this.iskeep) {
                this.isloading = true
                this.parameter.pageid += 1
                this.$nextTick(async function () {
                    const { data: res } = await this.$http.post(this.src, this.parameter)
                    if (res.msg === '成功') {
                        if (res.data.list) {
                            if (res.data.list.length < this.parameter.pagesize) {
                                this.isblank = true
                            }
                            res.data.list.forEach((i, index) => {
                                if (!i.goodsId | i.goodsId === -1) {
                                    i.goodsId = ''
                                }
                                if (!i.id | i.id === -1) {
                                    i.id = ''
                                }
                                this.couponList.push({
                                    src: i.mainPic,
                                    title: i.title,
                                    old_price: i.originalPrice,
                                    sell_num: i.monthSales,
                                    new_price: i.actualPrice,
                                    quan_num: i.couponPrice,
                                    iid: i.id,
                                    goodsId: i.goodsId
                            })
                        })
                        } else {
                            if (res.data.length < this.parameter.pagesize) {
                                this.isblank = true
                            }
                            res.data.forEach((i, index) => {
                                if (!i.goodsId | i.goodsId === -1) {
                                    i.goodsId = ''
                                }
                                if (!i.id | i.id === -1) {
                                    i.id = ''
                                }
                                this.couponList.push({
                                    src: i.mainPic,
                                    title: i.title,
                                    old_price: i.originalPrice,
                                    sell_num: i.monthSales,
                                    new_price: i.actualPrice,
                                    quan_num: i.couponPrice,
                                    iid: i.id,
                                    goodsId: i.goodsId
                            })
                        })
                        }
                    } else {
                        this.isblank = true
                    }
                    this.isloading = false
                })
            }
            if (this.selectedId === 0) {
                that.distance = 200
            } else {
                that.distance = 115
            }
            that.scrollTop = scrollTop
            if (that.scrollTop > that.distance) {
                that.isfixed = true
            } else if (that.scrollTop > 100) {
                that.isblock = true
            } else {
                that.isfixed = false
                that.isblock = false
            };
        },
        // backTop
        backTop () {
            const that = this
            const timer = setInterval(() => {
                const ispeed = Math.floor(-that.scrollTop / 5)
                document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
                if (that.scrollTop === 0) {
                    clearInterval(timer)
                }
            }, 16)
        },
        // swiper
        selectLabel (index, subcname) {
            this.discountsItem = [{
                label: '综合',
                src: 'goods/search',
                parameter: {
                    type: 0,
                    pageid: 1,
                    pagesize: 20,
                    keywords: subcname,
                    sort: 0,
                    pricelowerlimit: '',
                    priceupperlimit: '',
                    hascoupon: 1
                }
            }, {
                label: '销量',
                src: 'goods/search',
                parameter: {
                    type: 0,
                    pageid: 1,
                    pagesize: 20,
                    keywords: subcname,
                    sort: 0,
                    pricelowerlimit: '',
                    priceupperlimit: '',
                    hascoupon: 1
                }
            }, {
                label: '价格',
                src: 'goods/search',
                parameter: {
                    type: 0,
                    pageid: 1,
                    pagesize: 20,
                    keywords: subcname,
                    sort: 6,
                    pricelowerlimit: '',
                    priceupperlimit: '',
                    hascoupon: 1
                }
            }, {
                label: '佣金',
                src: 'goods/search',
                parameter: {
                    type: 0,
                    pageid: 1,
                    pagesize: 20,
                    keywords: subcname,
                    sort: 4,
                    pricelowerlimit: '',
                    priceupperlimit: '',
                    hascoupon: 1
                }
            }]
            this.selectLabelId = index
            this.isloading = true
            this.isinitloading = true
            this.isblank = false
            this.discountsIndex = 0
            this.couponList = []
            this.src = 'goods/search'
            this.parameter = {
                type: 0,
                pageid: 1,
                pagesize: 20,
                keywords: subcname,
                sort: 0,
                pricelowerlimit: '',
                priceupperlimit: '',
                hascoupon: 1
            }
            this.doSearch()
        },
        goSearch () {
            this.$router.push('/search')
        },
        doSearch () {
            this.$nextTick(async function () {
                const { data: res } = await this.$http.post(this.src, this.parameter)
                if (res.msg === '成功') {
                    if (res.data.list) {
                        if (res.data.list.length < this.parameter.pagesize) {
                            this.isblank = true
                        }
                        res.data.list.forEach((i, index) => {
                            if (!i.goodsId | i.goodsId === -1) {
                                    i.goodsId = ''
                                }
                                if (!i.id | i.id === -1) {
                                    i.id = ''
                                }
                            this.couponList.push({
                                src: i.mainPic,
                                title: i.title,
                                old_price: i.originalPrice,
                                sell_num: i.monthSales,
                                new_price: i.actualPrice,
                                quan_num: i.couponPrice,
                                iid: i.id,
                                goodsId: i.goodsId
                        })
                    })
                    } else {
                        if (res.data.length < this.parameter.pagesize) {
                            this.isblank = true
                        }
                        res.data.forEach((i, index) => {
                            if (!i.goodsId) {
                                    i.goodsId = ''
                                }
                                if (!i.id | i.id === -1) {
                                    i.id = ''
                                }
                            this.couponList.push({
                                src: i.mainPic,
                                title: i.title,
                                old_price: i.originalPrice,
                                sell_num: i.monthSales,
                                new_price: i.actualPrice,
                                quan_num: i.couponPrice,
                                iid: i.id,
                                goodsId: i.goodsId
                        })
                    })
                    }
                } else {
                    this.isblank = true
                }
            this.isloading = false
            this.isinitloading = false
        })
        },
        select_activity (index) {
            this.isactivity = true
            this.activity = this.swiperItem[index]
            this.activity.starttime = this.activity.starttime.slice(0, 10) + ' ' + this.activity.starttime.slice(11, 19)
            this.activity.endtime = this.activity.endtime.slice(0, 10) + ' ' + this.activity.endtime.slice(11, 19)
        },
        closeActivity () {
            this.isactivity = false
        },
        tpwdCopy () {
            this.istoast = true
            this.toastContent1 = '获取口令'
            this.toastContent2 = '打开淘宝app立即前往'
            setTimeout(() => {
                this.istoast = false
            }, 1500)
        },
        openKf () {
            this.iskf = true
        },
        closeKf () {
            this.iskf = false
        },
        pushLogin () {
            this.$router.push('/mine')
        }
    }
}
</script>

<style lang="less" scoped>
// header
.home_header_search {
    width: 60%;
    margin-left: 20%;
}
.home_header_right {
    right: 50px;
}
.header_r_svg {
    margin-top: 8px;
}
.header_r_svg img {
    width: 100%;
    height: 100%;
}
.home_header_right span {
    position: absolute;
    top: 0;
    width: 40px;
    margin-left: 5px;
}
.header_l_svg {
    background: url("../assets/head-l.svg");
}
// main_container
.main_container {
    margin-top: 45px;
    width: 100%;
}
// swiper
.swiper1 {
    height: 170px;
}
.swiper2 {
    height: 100%;
}
.swiper-slide img {
    width: 100%;
    height: 100%;
}
.high_light {
    color: rgb(221, 110, 58) !important;
    font-weight: bold;
}
.swiper2 .swiper-slide p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    text-align: center;
    width: 100%;
    font-size: 12px;
    z-index: 2;
    background-color: rgba(0,0,0,.2);
}

// discounts_items
.discounts_items {
    width: 100%;
    height: 40px;
    display: inline-block;
    background-color: #fff;
}
.discounts_items li {
    height: 40px;
    width: 20%;
    line-height: 40px;
    text-align: center;
    float: left;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
}
.discounts_items li.active {
    color: #ff2220;
    font-weight: bold;
}
// tips_container
.tips_container {
    width: 100%;
    padding: 10px 0 15px 0;
}
.tip_left {
    color: #fff;
    font-size: 13px;
    background: linear-gradient(to right,#FF6D37,#FF2822);
    padding: 6px 10px 5px 6px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    height: 20px;
    line-height: 20px;
}
.tip_right {
    float: right;
    font-size: 13px;
    color: #A7A7A7;
    padding-right: 10px;
    height: 20px;
    line-height: 20px;
    font-style: normal;
}
// activity_container
.activity_container {
    width: 100%;
    max-width: 640px;
    height: 100%;
    background: rgba(0, 0, 0, .66);
    z-index: 999;
    position: fixed;
    top: 0;
}
.activity_box {
    width: 80%;
    margin: 20% 10%;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
}
.activity_title {
    position: relative;
    width: 100%;
    height: 30px;
    line-height: 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding-top: 10px;
    color: #444;
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
}
.activity_img {
    margin-top: 10px;
    width: 100%;
    padding: 2px;
    height: 140px;
}
.activity_img img {
    width: 100%;
    height: 100%;
}
.activity_time {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
}
.time_left {
    width: 40%;
    text-align: center;
    float: left;
}
.time_right {
    float: left;
    width: 50%;
    margin: 0 5%;
}
.activity_tpwd {
    width: 100%;
    margin-top: 20px;
}
.activity_tpwd span {
    width: 30%;
    text-align: center;
    float: left;
    font-size: 13px;
    padding: 8px;
}
.activity_input {
    width: 60%;
    margin: 0 5%;
    float: left;
}
.activity_btn {
    width: 100%;
    margin: 10px -10px;
    text-align: right;
}
.pic_close {
    position: absolute;
    right: 13%;
    font-size: 0;
    line-height: initial;
}
.close_btn {
    position: absolute;
    right: -28px;
    top: -28px;
    width: 30px;
    height: 30px;
    margin-left: 0;
    font-size: 12px;
    display: inline-block;
    vertical-align: top;
}
.close_icon {
    background: url(../assets/icon.png) no-repeat;
    background-position: -149px -31px;
}
// kf_container
.kf_box {
    width: 60%;
    margin: 30% 20%;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
}
.kf_title {
    width: 100%;
    text-align: center;
    font-size: 14px;
}
.kf_content {
    width: 100%;
}
.kf {
    float: left;
    width: 80%;
    margin: 10% 10%;
    text-align: center;
    img {
        width: 100%;
        height: 100%;
    }
    span {
        font-size: 13px;
        color: rosybrown;
    }
}
.kf_button {
    width: 100%;
    text-align: center;
}
</style>
